import { FunctionComponent, useEffect, useRef } from 'react';

import { Box, Button, ButtonProps, styled } from '@mui/material';

import { ArrowCircleRight } from '@/components/Icons/ArrowCircleRight';
import { useUser } from '@/lib/queries/useUser';

const Container = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),

  '&.MuiButton-containedPrimary': {
    backgroundColor: '#64DFDF',
    border: '2px solid #64DFDF',
    boxShadow: '1px 3px 0px #4A8481',
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: '#2DB4B4',
      borderColor: '#2DB4B4',
    },
  },

  '&.MuiButton-containedTertiary': {
    backgroundColor: '#D40A60',
    border: '2px solid #D40A60',
    boxShadow: '1px 3px 0px #86244E',

    '&:hover': {
      backgroundColor: '#9A164E',
      borderColor: '#9A164E',
    },
  },
}));

export const TryWaldoNowButton: FunctionComponent<ButtonProps> = ({
  color = 'primary',
  ...props
}) => {
  const user = useUser();
  const href = useRef('/start');

  // When server side rendering, the link is not updated when there is a user
  useEffect(() => {
    if (user) {
      href.current = '/new-search';
    }
  }, [user]);

  return (
    <Container
      color={color}
      endIcon={<ArrowCircleRight />}
      href={href.current}
      variant="contained"
      {...props}
    >
      <Box textAlign="center">
        {user ? 'Search now' : "Try Waldo now – it's free"}
      </Box>
    </Container>
  );
};
