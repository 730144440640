import { FunctionComponent } from 'react';

import { Typography, TypographyProps } from '@mui/material';

export const WaldoDescription: FunctionComponent<TypographyProps> = ({
  children,
  ...props
}) => (
  <Typography variant="h5" color="text.secondary" {...props}>
    {!!children && <>{children}</>}
    {!children && (
      <>
        Waldo is the fastest way to find high quality information online. Cut
        the time you spend researching in half, using the best sources on the
        web.
      </>
    )}
  </Typography>
);
