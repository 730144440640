import { shuffle } from 'lodash';
import { FunctionComponent } from 'react';
import { Box, styled, Grid, Typography } from '@mui/material';

import { AndYou } from '@/components/Icons/AndYou';
import { Consultants } from '@/components/Icons/Consultants';
import { FinancialAnalysts } from '@/components/Icons/FinancialAnalysts';
import { Journalists } from '@/components/Icons/Journalists';
import { Strategists } from '@/components/Icons/Strategists';
import { Academics } from '@/components/Icons/Academics';
import { Typewriter } from '@/components/Typewriter';
import { WebResearchers } from '@/components/Icons/WebResearchers';

import { PageSlide } from '../PageSlide';
import { TryWaldoNowButton } from '../../TryWaldoNowButton';

import titleWords from './items.json';

const words = shuffle(titleWords);

const items: {
  title: string;
  description: string;
  icon: FunctionComponent<{ className?: string }>;
}[] = [
  {
    title: 'Journalists',
    description: 'Fact check your latest story with primary sources you trust.',
    icon: Journalists,
  },
  {
    title: 'Consultants',
    description:
      'Find a market size without falling down a rabbit hole of endless sources.',
    icon: Consultants,
  },
  {
    title: 'Web Researchers',
    description:
      'Pull quotes from industry experts right from your search results.',
    icon: WebResearchers,
  },
  {
    title: 'Strategists',
    description:
      'Find the facts and trends you need for your deck with half the clicks.',
    icon: Strategists,
  },
  {
    title: 'Financial Analysts',
    description:
      'Find datapoints and information faster than ever with our Lenses feature.',
    icon: FinancialAnalysts,
  },
  {
    title: 'Academics',
    description:
      'Speed up your research with tools that help you focus on the most pertinent insights.',
    icon: Academics,
  },
];

const WaldoIsGreatStyled = styled(PageSlide)(({ theme }) => ({
  backgroundColor: '#FBFBFB',
  color: theme.palette.common.black,
  padding: theme.spacing(5, 2),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 2),
  },
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(9),

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(6),
  },
}));

const TitleTypography = styled(Typography)(() => ({
  color: '#131313',
  width: '100%',
  wordBreak: 'break-all',
}));

export const WaldoIsGreat: FunctionComponent = () => (
  <WaldoIsGreatStyled px={2} py={10}>
    <TitleContainer>
      <TitleTypography align="center" variant="h1">
        Waldo is great for{' '}
        <Typewriter color="tertiary.main" variant="inherit" words={words} />
      </TitleTypography>
    </TitleContainer>
    <Box display="flex" alignItems="center" maxWidth="60rem">
      <Grid container spacing={4}>
        {items.map(({ description, icon: Icon, title }) => (
          <Grid item key={title} sm={12} md={6}>
            <Grid container spacing={1} wrap="nowrap">
              <Grid item>
                <Icon />
              </Grid>
              <Grid item xs>
                <Box mb={1}>
                  <Typography variant="h3" color="#131313">
                    {title}
                  </Typography>
                </Box>
                <Typography variant="h6" color="#131313">
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={1}
          >
            <Box mb={1}>
              <AndYou />
            </Box>
            <Box mb={1}>
              <Typography variant="h3" color="#131313">
                And You
              </Typography>
            </Box>
            <Box mb={6} maxWidth="32rem">
              <Typography align="center" variant="h6" color="#131313">
                Whether you're looking for industry trends or top product
                reviews, Waldo's got you covered.
              </Typography>
            </Box>
            <TryWaldoNowButton color="tertiary" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </WaldoIsGreatStyled>
);
