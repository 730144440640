import { FunctionComponent } from 'react';
import Head from 'next/head';

import { styled, Grid } from '@mui/material';

import { CompaniesUsingWaldo } from '@/components/Marketing/CompaniesUsingWaldo';
import { Footer } from '@/components/Marketing/Footer';
import { Header } from '@/components/Marketing/Header';
import { Testimonials } from '@/components/Marketing/Testimonials';
import { WaldoIsGreat } from '@/components/Marketing/WaldoIsGreat';
import { KeyFeatures } from '@/components/Marketing/KeyFeatures';
import DefaultTemplate from '@/components/templates/DefaultTemplate';
import { dark } from '@/styles/themes/site';
import { AuthErrorBoundary } from '@/error/AuthErrorBoundary';

const Container = styled(Grid)(() => ({
  overflow: 'hidden',
}));

const HomePage: FunctionComponent = () => (
  <AuthErrorBoundary>
    <DefaultTemplate theme={dark}>
      <Head>
        <title>Waldo / Search the web 2x faster.</title>
      </Head>
      <Container container spacing={0} direction="column">
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <CompaniesUsingWaldo />
        </Grid>
        <Grid item xs={12}>
          <KeyFeatures />
        </Grid>
        <Grid item xs={12}>
          <Testimonials />
        </Grid>
        <Grid item xs={12}>
          <WaldoIsGreat />
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Container>
    </DefaultTemplate>
  </AuthErrorBoundary>
);

export default HomePage;
