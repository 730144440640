import classnames from 'classnames';
import { FunctionComponent, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { PageSlide } from '../PageSlide';
import { CaretCircleDown } from '@/components/Icons/CaretCircleDown';
import { TryWaldoNowButton } from '@/components/TryWaldoNowButton';

const items: { title: string; description: string; image: string }[] = [
  {
    title: 'Accelerate Your Search',
    description:
      'Instead of being stuck with Google’s limited page previews, Waldo instantly deep scans each result and pulls out the useful parts in an easily skimmable, searchable format. Quickly get more insight without ever having to leave the page.',
    image: 'xray-search-frame',
  },
  {
    title: 'Track Down Data',
    description:
      'Finding the right data for a presentation or project can be a pain. Waldo is like a magnet that pulls out only the relevant stats or figures in one click, so you spend less time digging and more time doing.',
    image: 'data-extraction-frame',
  },
  {
    title: 'Generate New Ideas',
    description:
      'Lenses gives you the power to bypass the noise and see higher-quality search results from a curated collection of sites. You can use these collections to do things like view results through a particular political lens or find answers from industry-specific sources.',
    image: 'lens-frame',
  },
];

const maxAccordionContentWidth = 41;

const WaldoLivesStyled = styled(PageSlide)(({ theme }) => ({
  backgroundColor: '#ECFBFB',
  color: '#131313',
  minHeight: 0,
  padding: theme.spacing(2),

  '& h5': {
    color: '#585858',
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 4),
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: theme.spacing(76.5),
    padding: theme.spacing(10, 11),
  },
}));

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#131313',
  margin: 0,
  maxWidth: 'none',
  padding: 0,

  '&:before': {
    display: 'none',
  },

  '&.Mui-expanded': {
    marginBottom: theme.spacing(3),
    marginTop: 0,
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: theme.spacing(21),
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.spacing(31),
  },

  [theme.breakpoints.up('xl')]: {
    maxWidth: theme.spacing(41),
  },
}));

const Caret = styled(CaretCircleDown)(({ theme }) => ({
  color: '#131313',
  height: '32px !important',
  width: '32px !important',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),

  '&.expanded': {
    transform: 'rotate(180deg) !important',
  },
}));

const Container = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-start',
  display: 'flex',
  flexWrap: 'nowrap',

  [theme.breakpoints.up('sm')]: {
    flex: 1,
    justifyContent: 'center',
  },

  [theme.breakpoints.up('md')]: {
    flex: '0 0 auto',
    justifyContent: 'flex-start',
  },
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  margin: theme.spacing(0, -11),
  maxWidth: theme.spacing(90),
  width: '100%',
}));

const Details = styled(AccordionDetails)(({ theme }) => ({
  flexDirection: 'column',
  padding: 0,
  paddingBottom: theme.spacing(2),
  maxWidth: theme.spacing(maxAccordionContentWidth),

  [theme.breakpoints.up('lg')]: {
    paddingRight: theme.spacing(4),
  },

  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    textAlign: 'center',
  },
}));

const Frame = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  flexShrink: 0,
  marginLeft: theme.spacing(1),
}));

const Number = styled(Box)(() => ({
  fontFamily: 'Fraktion Mono',
  fontSize: '1.8125rem',
  letterSpacing: '-0.05em',
  lineHeight: '1.75rem',
}));

const Summary = styled(AccordionSummary)(({ theme }) => ({
  '&.MuiAccordionSummary-root': {
    borderTop: '1px solid',
    borderTopColor: '#D0D0D0',
    maxWidth: theme.spacing(maxAccordionContentWidth),
    padding: 0,

    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(4),
    },
  },
  '& div.MuiAccordionSummary-content': {
    border: 0,
    color: '#8B8B8B',
    justifyContent: 'space-between',
  },
  '& div.Mui-expanded': {
    color: '#131313',
    cursor: 'default',
  },
}));

const Title = styled(Box)(() => ({
  color: '#131313',
  fontFamily: 'Fraktion Mono',
  fontSize: '1.25rem',
  letterSpacing: '-0.04em',
  lineHeight: '1.5rem',
  textTransform: 'uppercase',
}));

export const KeyFeatures: FunctionComponent = () => {
  const theme = useTheme();
  const shouldScrollIntoView = useMediaQuery(theme.breakpoints.down('sm'));
  const [index, setIndex] = useState(0);
  const { image } = items[index];

  const handleAccordionChange =
    (idx: number) => (_: unknown, expanded: boolean) => {
      const newIndex = expanded ? idx : 0;
      setIndex(newIndex);

      if (shouldScrollIntoView) {
        const element = document.getElementById(
          `accordion-feature-${newIndex}`,
        );
        if (element) {
          // Let the accordion do it's own transition first.
          setTimeout(
            () =>
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              }),
            400,
          );
        }
      }
    };

  return (
    <WaldoLivesStyled>
      <Content>
        <Container>
          <Box>
            <Hidden smDown>
              <Title mb={1.5}>Key Features</Title>
            </Hidden>
            {items.map(({ description, image: thisImage, title }, i) => (
              <AccordionStyled
                elevation={0}
                expanded={index === i}
                id={`accordion-feature-${i}`}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                onChange={handleAccordionChange(i)}
              >
                <Summary>
                  <Typography variant="h3" color="inherit">
                    {title}
                  </Typography>
                  <Box display="flex">
                    <Number px={1}>0{i + 1}</Number>
                    <Caret
                      className={classnames({
                        expanded: index === i,
                      })}
                    />
                  </Box>
                </Summary>
                <Details>
                  <Hidden mdUp>
                    <Box mb={2}>
                      <img
                        alt=""
                        src={`/images/marketing/${thisImage}.png`}
                        srcSet={`/images/marketing/${thisImage}.png 1x, /images/marketing/${thisImage}@2x.png 2x`}
                      />
                    </Box>
                  </Hidden>
                  <Typography variant="h6" color="inherit">
                    {description}
                  </Typography>
                </Details>
              </AccordionStyled>
            ))}
          </Box>
        </Container>
        <Hidden smDown>
          <Frame>
            <img
              alt=""
              src={`/images/marketing/${image}.png`}
              srcSet={`/images/marketing/${image}.png 1x, /images/marketing/${image}@2x.png 2x`}
            />
          </Frame>
        </Hidden>
      </Content>
      <Box mx={2}>
        <TryWaldoNowButton />
      </Box>
    </WaldoLivesStyled>
  );
};
