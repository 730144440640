import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import Image from './icon.svg';

const Icon = styled(Image)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

export const AndYou: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="72"
    height="72"
    viewBox="0 0 72 72"
    {...props}
  />
);
