import { FunctionComponent } from 'react';

import { Box, styled, useMediaQuery, useTheme } from '@mui/material';

const LighterText = styled(Box)(() => ({
  color: '#DAFFFD',
}));

const WaldoTagLineStyled = styled(Box)(({ theme }) => ({
  color: '#64DFDF',
  fontSize: 48,
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '-0.05em',
  textAlign: 'center',

  '& span': {
    marginRight: '0.75rem',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: 64,
  },

  [theme.breakpoints.up('md')]: {
    fontSize: 80,
  },
}));

export const WaldoTagline: FunctionComponent = ({ children }) => {
  const theme = useTheme();
  const br = useMediaQuery(theme.breakpoints.down('sm'));
  const component = br ? 'div' : 'span';

  return (
    <WaldoTagLineStyled>
      {!!children && <Box component="span">{children}</Box>}
      {!children && (
        <>
          <Box component={component}>Search the web</Box>
          <LighterText component={component}>2x faster.</LighterText>
        </>
      )}
    </WaldoTagLineStyled>
  );
};
