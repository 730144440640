import { FunctionComponent } from 'react';

import { Box, Grid, styled } from '@mui/material';
import { PageSlide } from '../PageSlide';

const companies = [
  'bloomberg-logo',
  'facebook-logo',
  'cox-logo',
  'u-logo',
  'stanford-logo',
  'penn-logo',
];

const Companies = styled(PageSlide)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.common.black,
}));

const Title = styled(Box)(() => ({
  color: '#8B8B8B',
  fontFamily: 'Fraktion Mono',
  fontSize: '1.25rem',
  letterSpacing: '-0.04em',
  lineHeight: '1.5rem',
  textTransform: 'uppercase',
}));

export const CompaniesUsingWaldo: FunctionComponent = () => (
  <Companies minHeight={0} p={5}>
    <Title mb={2}>Saving Time At</Title>
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      {companies.map((logo) => (
        <Grid item key={logo}>
          <img
            alt=""
            src={`/images/marketing/${logo}.png`}
            srcSet={`/images/marketing/${logo}.png 1x, /images/marketing/${logo}@2x.png 2x`}
          />
        </Grid>
      ))}
    </Grid>
  </Companies>
);
