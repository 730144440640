import { FunctionComponent } from 'react';

import { Box, styled, Typography } from '@mui/material';

import { TryWaldoNowButton } from '@/components/TryWaldoNowButton';
import { Waldo } from '@/components/Icons/Waldo';
import { WaldoDescription } from '@/components/WaldoDescription';
import { WaldoTagline } from '@/components/WaldoTagline';

import { PageSlide } from '../PageSlide';
import { useUserLoadable } from '@/ext/app/state/user';
import { Link } from '@/components/atoms';
import { AuthErrorBoundary } from '@/error/AuthErrorBoundary';

const HeaderStyled = styled(PageSlide)(({ theme }) => ({
  backgroundColor: '#0F0F0F',
  justifyContent: 'center',
  minHeight: '60vh',
  padding: theme.spacing(5, 0, 5, 0),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 0, 9.5, 0),
  },
}));

const Description = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2, 5, 2),
  maxWidth: 'none',

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 2, 8.75, 2),
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.spacing(53),
  },

  [theme.breakpoints.up('xl')]: {
    maxWidth: theme.spacing(59),
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2, 3, 2),
  color: theme.palette.common.white,

  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 2, 5, 2),
  },
}));

const PricingLink = styled(Link)(({ theme }) => ({
  top: theme.spacing(1),
  right: theme.spacing(2),
  position: 'absolute',
}));

export const Header: FunctionComponent = () => {
  const loadable = useUserLoadable();
  const user = loadable.valueMaybe();

  return (
    <AuthErrorBoundary>
      <HeaderStyled>
        {!user && (
          <PricingLink href="/pricing" noVisited>
            <Typography variant="subtitle1">Pricing</Typography>
          </PricingLink>
        )}
        <Logo>
          <Waldo />
        </Logo>
        <Box mb={2.5} mx={2}>
          <WaldoTagline />
        </Box>
        <Description>
          <WaldoDescription align="center" />
        </Description>
        <Box mx={2}>
          <TryWaldoNowButton />
        </Box>
      </HeaderStyled>
    </AuthErrorBoundary>
  );
};
